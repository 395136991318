import { BasicsClass } from '@/common/BasicsClass';



export class orderManagement extends BasicsClass {
  //获取订单列表
  public getOrderList(form: any, page: any, cb: Function) {
    this.BasicPost('/animal/web/ordering/order/page', { ...form, ...page }, false, false,true, cb);
  }
  //订单详情
  public getOrderInfo(id:any,cb: Function) {
    this.BasicGet('/animal/web/ordering/order/getInfo', {id}, false, false, cb);
  }
  
  //发货
  public orderDelivery(form: any, cb: any): void {
    this.BasicPost('/animal/web/ordering/order/orderDelivery', {...form}, false, false, true, cb);
  }
  //整单取消
  public orderCancel(id: any, cb: any): void {
    console.log(id,'id21')
    this.BasicGet('/animal/web/ordering/order/cancel', {id}, false, false, cb);
  }
  //缺货配送
  public orderPartDelivery(form: any, cb: any): void {
    this.BasicPost('/animal/web/ordering/order/lackDelivery', {...form}, false, false, true, cb);
  }
}
