










































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import PageBox from "@/components/PageBox/PageBox.vue";
import AMapLoader from "@amap/amap-jsapi-loader";
import { Commodity } from "@/api/AppletWeb";
@Component({
    components: {
        PageBox,
    },
})
export default class orderInfo extends Vue {
    @Prop(Object) private infoData!: any;
    @Prop(Object) private voucherObj!: any;
    @Prop(String) private id!: any;
    private showPath = false;
    private destination = [];
    private showDestination = false;
    activated() {
        this.showPath = false;
        if (this.map) {
            this.map.destroy();
            this.map = null;
        }
    }
    private viewPath(deviceCode: any) {
        this.showPath = !this.showPath;
        if (this.showPath) {
            new Commodity().getOrderLocus(
                { id: this.id, deviceCode },
                (res: any) => {
                    let data = res.data;
                    console.log(1112);
                    const coordinates = data.locus.map((item: any) => [
                        item.longitude,
                        item.latitude,
                    ]);
                    console.log(coordinates);
                    this.trackPoints = [...coordinates];
                    this.destination = [
                        data.destination.longitude,
                        data.destination.latitude,
                    ];
                    const distance = this.calculateDistance(
                        data.locus[data.locus.length - 1].latitude,
                        data.locus[data.locus.length - 1].longitude,
                        data.destination.longitude,
                        data.destination.latitude
                    );
                    if (distance > 1) {
                        this.showDestination = true;
                    }
                    this.initMap();
                }
            );
        } else {
            this.map.destroy();
            this.map = null;
        }
    }
    MAP_PLUGINS = [
        "AMap.Scale",
        "AMap.ToolBar",
        "AMap.ControlBar",
        "AMap.HawkEye",
        "AMap.Circle",
        "AMap.Polygon",
        "AMap.Polyline",
        "AMap.Marker",
        "AMap.Pixel",
        "AMap.InfoWindow",
        "AMap.Icon",
    ];
    map = null; //初始化 map 对象
    markers = []; // 存储标记
    polyline = null; //存储划线
    trackPoints = [];
    // 初始化地图
    mounted() {}
    initMap() {
        window._AMapSecurityConfig = {
            securityJsCode: "69c351b81e6df1d13fde026d56e2da78",
        };
        AMapLoader.load({
            key: "935a50506de9178c94c3fcb685910705", // 申请好的Web端开发者Key，首次调用 load 时必填
            version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            plugins: this.MAP_PLUGINS, // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        })
            .then((AMap) => {
                console.log(111);
                // 初始话地图对象
                const map = new AMap.Map("container", {
                    viewMode: "3D",
                    terrain: true,
                    zoom: 4.5,
                    center: [116.41, 39.91],
                });
                this.map = map;
                console.log(this.map);
                this.trajectory(); //轨迹
            })
            .catch((e) => {
                console.log(e);
            });
    }
    calculateDistance(lat1, lon1, lat2, lon2) {
        //经纬度比较
        const R = 6371; // 地球半径，单位公里
        const dLat = ((lat2 - lat1) * Math.PI) / 180;
        const dLon = ((lon2 - lon1) * Math.PI) / 180;
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos((lat1 * Math.PI) / 180) *
                Math.cos((lat2 * Math.PI) / 180) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // 得到距离，单位公里
        return distance;
    }
    // 轨迹
    trajectory() {
        if (this.trackPoints.length > 1) {
            this.addStartPointMarker(); //开始的点
        }

        this.addPolyline(); //轨迹的线ADD
        if (this.showDestination) {
            this.addEndPointMarker(); //结束的点
            this.addDestination(); //终点
        } else {
            this.addEndPointMarker(); //结束的点
        }

        console.log(this.map, 577);
        this.map.setFitView();
    }
    addStartPointMarker() {
        if (!this.map) {
            console.error(
                "Map is not initialized when trying to add start marker"
            );
            return;
        }
        const icon = new AMap.Icon({
            size: new AMap.Size(20, 30), // 设置图标的宽度和高度
            imageSize: new AMap.Size(20, 30), // 设置图标显示时的大小
            image:
                "https://marktrace-cattle.oss-cn-hangzhou.aliyuncs.com/officialweb/home_zh/qidian.png", // 自定义图标的 URL
        });
        console.log(this.trackPoints[0]);
        console.log(this.map);
        const startPoint = new AMap.Marker({
            position: this.trackPoints[0],
            map: this.map,
            icon: icon,
            offset: new AMap.Pixel(-10, -30),
        });
        console.log(startPoint, 544654);
        this.markers.push(startPoint);
    }
    addDestination() {
        console.log(this.destination, 2222);

        const lastPoint = this.destination;
        const icon = new AMap.Icon({
            size: new AMap.Size(20, 30), // 设置图标的宽度和高度
            imageSize: new AMap.Size(20, 30), // 设置图标显示时的大小
            image:
                "https://marktrace-cattle.oss-cn-hangzhou.aliyuncs.com/officialweb/home_zh/zhongdian.png", // 自定义图标的 URL
        });
        console.log(lastPoint, 611);
        const endPoint = new AMap.Marker({
            position: lastPoint,
            map: this.map,
            icon: icon,
            offset: new AMap.Pixel(-10, -30),
        });
        this.markers.push(endPoint);
    }
    addEndPointMarker() {
        console.log(this.trackPoints, 2222);

        const lastPoint = this.trackPoints[this.trackPoints.length - 1];
        const icon = new AMap.Icon({
            size: this.showDestination
                ? new AMap.Size(71, 35)
                : new AMap.Size(20, 30), // 设置图标的宽度和高度
            imageSize: this.showDestination
                ? new AMap.Size(71, 35)
                : new AMap.Size(20, 30), // 设置图标显示时的大小
            image: this.showDestination
                ? "https://marktrace-cattle.oss-cn-hangzhou.aliyuncs.com/officialweb/home_zh/order_che.png"
                : "https://marktrace-cattle.oss-cn-hangzhou.aliyuncs.com/officialweb/home_zh/zhongdian.png", // 自定义图标的 URL
        });
        console.log(lastPoint, 611);
        const endPoint = new AMap.Marker({
            position: lastPoint,
            map: this.map,
            icon: icon,
            offset: new AMap.Pixel(-10, -30),
        });
        this.markers.push(endPoint);
    }
    addPolyline() {
        console.log(this.trackPoints, 333);

        this.polyline = new AMap.Polyline({
            path: this.trackPoints,
            isOutline: true,
            outlineColor: "#ffeeee",
            borderWeight: 2,
            strokeColor: "#0091ff",
            strokeOpacity: 0.9,
            strokeWeight: 6,
            lineJoin: "round",
        });
        this.map.add(this.polyline);
    }
}
