











































import { Component, Vue } from "vue-property-decorator";
import PageBox from "@/components/PageBox/PageBox.vue";
import OrderInfo from "@/views/BeefOrdering/orderManagement/components/orderInfo/OrderInfo.vue";
import ShopInfo from "@/views/BeefOrdering/orderManagement/components/shopInfo/ShopInfo.vue";
import SaleInfo from "@/views/BeefOrdering/orderManagement/components/saleInfo/SaleInfo.vue";

import { orderManagement } from "@/common/Views/BeefOrdering/orderManagement/orderManagement";
@Component({
    components: {
        PageBox,
        OrderInfo,
        ShopInfo,
        SaleInfo,
    },
})
export default class viewOrderInfo extends Vue {
    private isorder = 1;
    private infoData = {};
    private productList = [];
    private SaleList = [];
    private userObj = {
        user: {},
        farmName: {},
    };
    private voucherObj = {
      orderVoucherObj:{},
      remarkObj:{},
      payVoucherObj:{}
    }
    private switchTab(type: number) {
        this.isorder = type;
    }
    private id = "";
    activated() {
        this.isorder = 1;
        const query = this.$route.query;
        console.log(query);
        this.id = query.id;
        console.log(this.id, "id")
        this.getOrderInfo(query);
        this.getSaleList(query);
    }
    private getOrderInfo(query: any) {
        new orderManagement().getOrderInfo(query.id, (res: any) => {
            let data = res.data;
            if (data.orderDelivery != null && data.orderDelivery.receiptCertificate) {
                data.orderDelivery.receiptCertificate = data.orderDelivery.receiptCertificate.split(',')
            }
            console.log(data.orderDelivery);
            
            this.infoData = data;

            this.productList = data.orderGoodsList;

        });
    }
    private getSaleList(query: any) {
        // new Commodity().afterSaleList(query.id, (res: any) => {
        //     let data = res.data;
        //     data.forEach((item: any) => {
        //         if (item.refundImgs) {
        //             if (item.refundImgs.includes(",")) {
        //                 item.refundImgs = item.refundImgs.split(",");
        //             } else {
        //                 item.refundImgs = [item.refundImgs];
        //             }
        //         } else {
        //             item.refundImgs = [];
        //         }
        //     });
        //     this.SaleList = data;
        // });
    }
}
